import "../../node_modules/bootstrap/dist/js/bootstrap";

import * as styles from "../SCSS/module.scss";

styles();

import "../../node_modules/scroll-out";

ScrollOut({
  targets: ".fadeIn",
  once: "true",
  onShown: function (el) {
    // remove the class
    el.classList.remove("animate__animated", "animate__fadeIn");
    // force reflow
    void el.offsetWidth;
    // re-add the animated cl
    el.classList.add("animate__animated", "animate__fadeIn");
  },
});
